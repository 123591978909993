<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center">
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1 font-weight-bold">{{ $t(`label.preOrder`) }}</span>
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="center" class="my-8" no-gutters>
      <v-col cols="11" lg="9">
        <v-row>
          <v-col md="7" cols="12">
            <v-card class="pa-sm-6 pa-4 py-6 rounded-lg" color="transparent" tile elevation="5">
              <!-- Billing Address -->
              <div class="mb-6">
                <div class="font-weight-bold mb-2 text-sm-body-1 text-body-2">{{ $t(`label.yourBillingAddress`) }}</div>
                <div v-if="billingAddress != null" class="billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2" @click="openChangeBillingAddressDialog">
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="10">
                      <div class="font-weight-bold">{{ billingAddress.display_name }}</div>
                      <div>{{ billingAddress.phone }}</div>
                      <div>{{ billingAddress.address }}</div>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon v-text="'$arrowRightIcon'" class="stroke--unbox_green"></v-icon>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2" @click="openAddAddressDialog">
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="10">
                      <div class="font-weight-bold">{{ $t(`label.addAddress`) }}</div>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon v-text="'$plusCircleIcon'" class="stroke--unbox_green"></v-icon>
                    </v-col>
                  </v-row>
                </div>
                <div class="red--text text-body-2 mb-2" v-if="isBillingAddressError">{{ $t(`label.invalidBillingAddress`) }}</div>
              </div>

              <!-- Checkout Items -->
              <div class="mb-6">
                <div class="font-weight-bold mb-2">{{ $t(`label.yourItems`) }}</div>
                <v-data-table
                  :items-per-page="checkoutCart.length"
                  class="cart-table unbox_scrollbar"
                  item-key="productDisplaySku"
                  :items="checkoutCart"
                  hide-default-footer
                  ref="cartTable"
                  :no-data-text="$t(`label.cartEmpty`)"
                >
                  <template v-slot:item="{ item }">
                    <template>
                      <tr>
                        <td class="full-width px-0 d-table">
                          <v-card class="my-2 mx-1 rounded-lg" color="transparent" tile elevation="2">
                            <table class="full-width d-table">
                              <tr>
                                <td class="cart-productColumn image cursor-pointer v-align-top" @click="goToProductDetailPage(item)">
                                  <img class="cart-productImage ma-2" :src="`${shared.MEDIA_SERVER_URL}/products/variants/${item.productDisplaySku}.png`" alt="" />
                                </td>
                                <td class="cart-productColumn">
                                  <div class="py-4 px-2">
                                    <div class="cursor-pointer" @click="goToProductDetailPage(item)">
                                      <div class="font-weight-bold text-sm-body-1 text-body-2 mb-2">{{ item.productName }}</div>
                                      <div class="text-sm-body-1 text-body-2 mb-2">{{ item.variation }}</div>
                                      <div class="font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2">{{ $shared.formatCurrency(item.unitPrice) }}</div>
                                    </div>
                                    <v-row align="center" class="mt-2">
                                      <v-col sm="auto">
                                        <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                      </v-col>
                                      <v-col sm="auto">
                                        <div style="width: 120px">
                                          <v-text-field class="cart-quantity" :readonly="true" hide-details outlined v-model.number="item.quantity" dense type="number"></v-text-field>
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row v-if="item.insurance">
                                      <v-col cols="12">
                                        <div>
                                          <v-row no-gutters align="top" class="mb-2">
                                            <v-col cols="auto"><v-checkbox class="mt-0" hide-details v-model="item.requiredInsurance"></v-checkbox></v-col>
                                            <v-col>
                                              <p class="mb-0">
                                                {{ $t(`label.insurancePlan`, [$shared.formatCurrency(item.insurance.unit_price)]) }}
                                                <span class="link-text cursor-pointer" @click="openProtectionPlanPage">
                                                  {{ $t(`label.tncsApply`) }}
                                                  <v-icon small v-text="'$exclamationMarkIcon'" class="stroke--link_text"></v-icon>
                                                </span>
                                              </p>
                                            </v-col>
                                          </v-row>
                                          <div v-if="item.requiredInsurance">
                                            <div class="font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2">{{ $shared.formatCurrency(item.insurance.unit_price) }}</div>
                                            <div>
                                              <v-row align="center" class="mt-2">
                                                <v-col sm="auto">
                                                  <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                                </v-col>
                                                <v-col sm="auto">
                                                  <div style="width: 120px">
                                                    <v-text-field
                                                      :readonly="true"
                                                      class="cart-quantity"
                                                      hide-details
                                                      outlined
                                                      v-model.number="item.insurance.quantity"
                                                      dense
                                                      type="number"
                                                    ></v-text-field>
                                                  </div>
                                                </v-col>
                                              </v-row>
                                            </div>
                                          </div>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </v-card>
                        </td>
                      </tr>
                    </template>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
          <v-col md="5" cols="12">
            <v-card class="pa-6 rounded-lg" color="transparent" tile elevation="5">
              <v-row justify="space-between" class="mb-3" no-gutters>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">{{ $t(`label.tax`) }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">
                    {{ $shared.formatCurrency(0) }}
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto">
                  <div class="font-weight-bold unbox_black--text text-body-1">{{ $t(`label.total`) }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="font-weight-bold unbox_red--text text-body-1">
                    {{ $shared.formatCurrency(calculateTotalCartAmount(checkoutCart)) }}
                  </div>
                </v-col>
              </v-row>

              <div class="red--text text-body-2 my-4" v-if="noFullEnterCheckoutForm">{{ $t(`label.pleaseSelectCheckout`) }}</div>
              <v-btn block color="unbox_red" class="rounded-lg font-weight-bold text-body-1 py-6 white--text" @click="placePreOrder">
                {{ $t(`label.placeOrder`) }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- CHANGE ADDRESS DIALOG -->
    <app-dialog :dialogShow="changeAddressDialogShow" :title="$t(`action.changeAddress`)" :closeAction="() => (changeAddressDialogShow = !changeAddressDialogShow)">
      <template v-slot:body>
        <div class="changeAddressFrom-tab">
          <app-change-address :selectedAddressId="selectedAddressId" :updateSelectedAddress="updateSelectedAddress"></app-change-address>
        </div>
      </template>
    </app-dialog>
  </v-card>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import AppChangeAddress from '@/components/shop/changeAddress'
import { ORDER_PRE_ORDER, ORDER_UPDATE_STATUS } from '@/store/order.module'
import { CUSTOMER_GET_INFORMATION } from '@/store/customer.module'
import { sharedHelper, localeHelper } from '@/utils'
import { SESSION, SHARED } from '@/constants'
export default {
  name: 'preOrder',
  components: {
    AppChangeAddress
  },
  data: () => ({
    currentUpdateAddress: '',
    selectedAddressId: '',
    shared: SHARED,
    checkoutCart: [],
    noFullEnterCheckoutForm: false,
    billingAddress: null,
    changeAddressDialogShow: false,
    isBillingAddressError: false
  }),
  computed: {
    customerInfo() {
      return this.$store.state.customer.info
    },
    editAddressResponseComplete() {
      return this.$store.state.customer.editAddressResponse.complete
    },
    addAddressResponseComplete() {
      return this.$store.state.customer.addAddressResponse.complete
    },
    preOrderResponseComplete() {
      return this.$store.state.order.preOrderResponse.complete
    },
    order() {
      return this.$store.state.order.order
    },
    updateOrderStatusResponse() {
      return this.$store.state.order.updateOrderStatusResponse
    }
  },
  watch: {
    async editAddressResponseComplete() {
      let response = this.$store.state.customer.editAddressResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.deliveryAddressDialogShow = false
          await this.getCustomerInformation()
        }
      }
    },
    async addAddressResponseComplete() {
      let response = this.$store.state.customer.addAddressResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.deliveryAddressDialogShow = false
          await this.getCustomerInformation()
        }
      }
    },
    async preOrderResponseComplete() {
      let response = this.$store.state.order.preOrderResponse
      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          let d = this.$store.state.order.order
          let data = {
            isSendEmail: true,
            isOrderSealed: true,
            orderUuid: d.uuid,
            action: 'preOrder'
          }
          await this.$store.dispatch(ORDER_UPDATE_STATUS, { data })
        }
      }
    },
    updateOrderStatusResponse() {
      let response = this.$store.state.order.updateOrderStatusResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.preOrderComplete`))
          this.$router.push({
            name: ROUTE_NAME.ORDER_SUMMARY_PAGE,
            params: {
              orderType: 'preorder'
            }
          })
        }
      }
    }
  },
  async created() {
    await this.checkIsNewOrder()
    await this.getCustomerInformation()
  },
  methods: {
    openProtectionPlanPage() {
      let route = this.$router.resolve({
        name: ROUTE_NAME.PROTECTION_PLAN
      })
      window.open(route.href, '_blank')
    },
    async checkIsNewOrder() {
      this.geBuyNowCartInformation()
    },
    async getCustomerInformation() {
      let data = {
        uuid: localStorage.getItem(SESSION.CUSTOMER_UUID)
      }
      await this.$store.dispatch(CUSTOMER_GET_INFORMATION, { data })
      this.setAddressInfo()
    },
    setAddressInfo() {
      let billingAddressData = this.customerInfo.addresses.find((x) => x.is_default_billing)

      if (billingAddressData != undefined) {
        let billingAddressString = [
          billingAddressData.address_1,
          billingAddressData.address_2,
          billingAddressData.address_3,
          billingAddressData.postcode,
          billingAddressData.city,
          billingAddressData.state
        ]
          .filter((x) => x != null)
          .join(' ')

        this.billingAddress = {
          display_name: billingAddressData.display_name,
          phone: `${billingAddressData.phone_code}-${billingAddressData.phone_no}`,
          address: billingAddressString
        }
      }
    },
    geBuyNowCartInformation() {
      let buyNowCart = localStorage.getItem(SESSION.BUYNOW_CART)
      if (buyNowCart !== undefined) {
        this.checkoutCart = JSON.parse(buyNowCart)
      }
    },
    calculateTotalCartAmount(cartProduct) {
      let totalCartAmount = 0

      cartProduct.forEach((product) => {
        totalCartAmount += product.unitPrice * product.quantity
        if (product.requiredInsurance) {
          totalCartAmount += product.insurance.unit_price * product.insurance.quantity
        }
      })

      return totalCartAmount
    },
    openAddAddressDialog() {
      this.$root.$master.deliveryAddressObj.isEdit = false
      this.$root.$master.deliveryAddressDialogShow = true
      this.$root.$master.deliveryAddressObj.isFirstAddress = this.customerInfo.addresses.length <= 0
    },
    openChangeBillingAddressDialog() {
      this.selectedAddressId = this.billingAddress.uuid
      this.changeAddressDialogShow = true
      this.currentUpdateAddress = 'billing'
    },
    updateSelectedAddress(value) {
      this.changeAddressDialogShow = false
      if (this.currentUpdateAddress == 'billing') {
        let billingAddressData = this.customerInfo.addresses.find((x) => x.uuid == value)

        let billingAddressString = [
          billingAddressData.address_1,
          billingAddressData.address_2,
          billingAddressData.address_3,
          billingAddressData.postcode,
          billingAddressData.city,
          billingAddressData.state
        ]
          .filter((x) => x != null)
          .join(' ')

        this.billingAddress = {
          display_name: billingAddressData.display_name,
          phone: `${billingAddressData.phone_code}-${billingAddressData.phone_no}`,
          address: billingAddressString
        }
      }
    },
    placePreOrder() {
      let isCompleteInfo = true
      this.isBillingAddressError = false

      if (this.billingAddress == null) {
        this.isBillingAddressError = true
        isCompleteInfo = false
      }

      if (isCompleteInfo) {
        let checkoutProduct = []

        this.checkoutCart.forEach((d) => {
          checkoutProduct.push({
            display_sku: d.productDisplaySku,
            quantity: d.quantity,
            unit_price: d.unitPrice,
            deposit_amount: 0,
            discount_code: '',
            discount_percentage: 0,
            discount_amount: 0,
            discount_remark: '',
            mark_up_price: 0,
            mark_up_percentage: 0,
            mark_up_remark: '',
            product_type: d.product_type,
            remark: d.variation
          })

          if (d.insurance && d.requiredInsurance) {
            checkoutProduct.push({
              display_sku: d.insurance.display_sku,
              quantity: d.insurance.quantity,
              unit_price: d.insurance.unit_price,
              deposit_amount: 0,
              discount_code: '',
              discount_percentage: 0,
              discount_amount: 0,
              discount_remark: '',
              mark_up_price: 0,
              mark_up_percentage: 0,
              mark_up_remark: '',
              product_type: d.product_type,
              remark: `${d.insurance.remark}\nQuantity x${d.insurance.quantity}`
            })
          }
        })

        let data = {
          currency: 'MYR',
          depositAmount: 0,
          dicountCode: this.promoCode,
          discountAmount: this.promoDiscount,
          contactEmail: this.customerInfo.email,
          contactPhoneNumber: `${this.customerInfo.phoneCode}-${this.customerInfo.phone}`,
          outletId: this.outletId,
          billingName: this.billingAddress.display_name,
          billingAddress: this.billingAddress.address,
          billingPhoneNumber: this.billingAddress.phone,
          product: checkoutProduct
        }

        this.$store.dispatch(ORDER_PRE_ORDER, { data })
      }
    },
    updateShoppingCart() {
      let cartJsonString = localStorage.getItem(SESSION.CART)
      if (cartJsonString !== undefined && cartJsonString !== null) {
        let cart = JSON.parse(cartJsonString).product

        this.checkoutCart.forEach((x) => {
          cart = cart.filter((y) => y.display_sku != x.display_sku)
        })

        sharedHelper.setCartItems(cart)
        this.$root.$master.updateCartInfo()
      }
    },
    goToProductDetailPage(item) {
      let productName = item.productName.replace(/\s+/g, '-').toLowerCase()
      this.$router.push({
        name: ROUTE_NAME.SHOP_PRODUCT,
        params: {
          productName: `${productName}-${SHARED.PRODUCT_GROUP_PREFIX}${item.group}`
        }
      })
    }
  }
}
</script>

<style lang="scss">
.delivery-option-button {
  width: 100% !important;
  border: 2px solid var(--v-light_gray-base);
  &.active {
    border-color: var(--v-unbox_primary-base);
    * {
      font-weight: bold !important;
    }
  }
}
.billing-address-box {
  background: #daccbf;
}
.pickup-location-box {
  div {
    margin: 12px 0 12px;
    border-bottom: 1px solid var(--v-text_light_gray-base);
  }
}

.checkout-payment-icon {
  width: 25px;
}

.delivery-option-button-box {
  max-width: 220px;
}

@media only screen and (max-width: 599px) {
  .delivery-option-button-box {
    max-width: 50%;
  }
}

@media only screen and (max-width: 400px) {
  .delivery-option-button-box {
    max-width: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.checkbox-ticker {
  width: 15px;
}

.cartDisable {
  opacity: 0.4;
}
</style>
